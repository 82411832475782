html::-webkit-scrollbar {
    display: none;
    -webkit-overflow-scrolling: touch;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Nunito", sans-serif;
}

img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}


/* login signup css */

.loginForm {
    padding: 20px 20px 10px 20px;
    max-width: 502px;
    margin-top: 30px;
}

.loginForm a {
    text-decoration: none;
    font-weight: 700;
    color: rgb(230, 86, 19);
    font-size: 1rem !important;
}

.loginGrid {
    height: 100vh;
    padding: 30px 5px;
    background-color: #000;
}

@media only screen and (max-width: 413px) {
    .loginGrid {
        height: 100%;
    }
}


/* end */

.login {
    width: 100%;
    min-height: 100vh;
    min-width: 520px;
    padding: 0 20px;
    background: #e9e9e9;
    display: flex;
}

.login .loginContainer {
    padding: 60px;
    margin: auto;
    width: 100%;
    max-width: 520px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
    box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer label {
    color: white;
    margin: 14px 0;
    display: block;
    font-size: 22px;
    line-height: 1;
}

.login .loginContainer input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 19px;
    padding: 10px 40px 10px 10px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    letter-spacing: 1px;
}

.login .loginContainer h1 {
    text-align: center;
    color: #fff;
}

.login .loginContainer .btnContainer {
    width: 100%;
    padding: 24px 0;
}

.login .loginContainer .btnContainer p {
    margin: 14px 0 0 0;
    text-align: right;
    color: #fff;
}

.login .loginContainer .btnContainer p span {
    color: yellow;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
}

.login .loginContainer .errorMsg {
    margin-top: 15px;
    color: red;
    font-size: 16px;
}

.home {
    width: 100%;
    min-height: 100vh;
    /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
    background: black;
}

.navbar {
    padding: 0;
    position: fixed;
    width: 100vw;
    z-index: 22;
    color: black;
    margin-bottom: 250px;
}

.navbar a {
    position: absolute;
    left: 8%;
    color: #fff;
    font-size: 1.5rem !important;
}

.navbar a:hover {
    text-decoration: none;
    color: #c04848;
}

.navbar nav {
    background: #603bbb;
    width: 100%;
    padding: 8px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar nav button {
    width: 86px;
    padding: 8px 0;
    background: #3a2b58;
}


/* Profile picture */

#user-name {
    position: relative;
    left: 60%;
}


/* #user-name div .name {
  border: 1px solid black;
} */

#user-avatar {
    position: relative;
    left: 15%;
}

#avatar {
    margin-top: -10px !important;
    height: 20px;
    width: 85px;
    border-radius: 50%;
}

.video-model {
    height: 50vh;
    width: 50vh;
}


/* home category name */

.category-name {
    margin-left: 10px;
    color: white;
    user-select: none;
    font-weight: 900;
    font-size: 1.2rem;
}

.home-content {
    padding-top: 30px;
}

.user-profile {
    height: 200px;
}

.loginContainer .user-profile img {
    position: relative;
    left: 90px;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    background-color: #ffffff;
    border: 1px solid red;
}

.loginContainer .user-profile input {
    position: relative;
    top: -71px;
    left: -110px;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    /* background-color: transparent; */
    opacity: 0;
    z-index: 2;
    cursor: pointer;
}

#profile-progress {
    width: 130%;
    position: relative;
    top: -69px;
    left: -60px;
}


/* #profile-progress #progress-bar {
  width: 100%;
  position: relative;
  top: 22px;
} */


/* #profile-progress p {
  position: relative;
  text-align: center;
  top: -6px;
} */

.user-profile button {
    position: relative;
    bottom: 125px;
    left: 320px;
    width: 80px;
}

.profileContainer {
    margin-top: 75px !important;
    padding: 13px 60px !important;
}

.video-progress {
    position: relative;
    top: -16px;
}

.thumbnail-progress {
    position: relative;
    top: -16px;
}

#modal-thumbnail {
    height: 100px;
}

.text-success {
    display: flex;
    margin: auto;
}

.text-primary {
    display: flex;
    margin: auto;
}

hr {
    border-top: 1px solid rgba(255, 255, 255, 0.57) !important;
}

.main-carousel {
    cursor: pointer;
    width: 80%;
    margin: 0 auto;
}

.img:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 30%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1));
    /* box-shadow: 15px 0px 100px 50px black inset; */
    /* background:rgba(0,0,0,0.6); */
    /* opacity: 1; */
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

@media only screen and (max-width: 522px) {
    #signUpName {
        width: 100%;
    }
    .main-carousel {
        margin-top: 50px;
        width: 100%;
    }
    .margin-top {
        margin-top: 50px;
    }
    .carousel-control-next,
    .carousel-control-prev {
        top: 35px !important;
    }
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
    display: none;
}

* {
    box-sizing: border-box;
}


/* .flickity-enabled {
  top: 4rem !important;
} */

.header-container {
    color: #000000;
    background-color: #fff;
    border-bottom: 1px solid #23b5b5;
    width: 100%;
    height: 4rem;
    /* position: fixed; */
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    padding: 0% 1%;
    margin-bottom: 2vw;
}

.cat {
    padding: 0% 1%;
    color: #000;
    transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: color;
}

.cat a {
    color: #000;
}

.cat:hover {
    cursor: pointer;
}

.cat a:hover {
    color: #c7c7c7;
    text-decoration: none;
}

.exp-text {
    margin-left: 150px;
    margin-right: 25px;
    font-size: 1.3rem;
    padding: 0% 1% 0% 1%;
}

.exp-text a:first-child {
    margin-right: 1vw;
}

.exp-text a {
    text-decoration: none;
    color: inherit;
}

.to-right {
    display: flex;
    align-items: center;
    position: fixed;
    right: 100px;
    float: right;
}

.to-right .explified .logout-button {
    display: none;
    position: fixed;
    color: #23b5b5 !important;
    background-color: #3a2b58 !important;
}

.to-right .explified:hover .logout-button {
    display: block;
}

.user-search input {
    outline: none;
    border: 0;
    /* height: 1.2rem; */
    background: transparent;
    width: 200px;
    font-size: 0.9rem;
    /* color: #ffffff; */
    border-radius: 5px;
    padding: 4px 16px 4px 5px;
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-search input:focus,
.user-search input:hover {
    border: 1.5px solid #23b5b5;
}

.allow-pad {
    padding: 0px 25px;
}

.exp-text:hover,
.explified:hover,
.exp-logo:hover {
    cursor: pointer;
}

.exp-text a:hover {
    text-decoration: none;
    color: inherit;
}

.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: none;
    /* padding: 0% 1%; */
    outline: none;
}

.line {
    fill: none;
    stroke: #ffffff;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}

.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}

.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

@media only screen and (max-width: 860px) {
    .menu {
        display: block;
    }
    .cat {
        display: none;
    }
    .explified {
        display: none;
    }
    .logout-but {
        display: none;
    }
}


/* Phone Input css overriding */

.react-tel-input .form-control {
    width: 100%;
    height: 56px;
}