.VideoEditor-main-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 10px;
    height: calc(100vh - 4rem);
    background-color: #fff;
    border-right: solid 2px rgba(255, 255, 255, 0.57);
}

.VideoEditor-main-sidebar>#create-list-btn {
    text-align: center;
}

.VideoEditor-main-sidebar>#create-list-btn>#creator-list-btn-item {
    background-color: #fff;
    border-radius: 5px;
    display: none;
    position: absolute;
    left: 24px;
    width: 152px;
    flex-direction: column;
    box-shadow: 0 0 1px 0 #000;
}

.VideoEditor-main-sidebar>#create-list-btn>#creator-list-btn-item>#item {
    background-color: #fff;
    color: #000;
    display: flex;
    cursor: pointer;
    padding: 5px;
    justify-content: space-evenly;
    transition: all 0.3s;
}

.VideoEditor-main-sidebar>#create-list-btn>#creator-list-btn-item>#item:hover {
    background-color: #000;
    color: #fff;
}

.VideoEditor-main-sidebar>#create-list-btn:hover>#creator-list-btn-item {
    display: flex;
}

.VideoEditor-main-sidebar>#creator-list-func {
    font-size: 20px;
    color: black;
}

.VideoEditor-main-sidebar>#creator-list-func>#creator-list-func-item {
    cursor: pointer;
    padding: 5px;
    transition: linear 0.3s;
}

.VideoEditor-main-sidebar>#creator-list-func>#creator-list-func-item>#item> :first-child {
    margin-right: 10px;
}

.VideoEditor-main-sidebar>#creator-list-func>#creator-list-func-item:hover {
    background-color: #000;
    color: #fff;
}