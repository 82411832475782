.Navbaredits_leftsidebar_content{
  width:25%;
  height: 78vh;
  
  background-color: rgb(15,15,15);
  margin:10px;
  padding: 10px;
  margin-bottom: 0px;
}

.Navbaredits_leftsidebar_name{
  font-size: 15px;
  background-color: rgb(50,50,50);
  margin: 2px;
  color: white;
  padding: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.NavBarEditsName{
  display:flex;
  flex-direction: column;
  margin: 2px;
}

.ModifyFeildInput{
  width:90%;
  color:black;
  background-color: white;
  left:5%;
}

.FeildsListName{
  overflow-y: scroll;
  width: 100%;
  height: 50%;
  background-color: rgb(90,90,90);
}

.FeildsListName::-webkit-scrollbar {
  display: none;
}

.FeildsListName {
  -ms-overflow-style: none;
  scrollbar-width: none;
}