.VideoEditor-sub-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5vw;
    font-size: 1.5rem;
    background-color: rgb(15, 15, 15);
}

.VideoEditor-sub-navbar-container {
    background: rgb(15, 15, 15);
    height: 3rem;
    padding: 1.2rem 2rem;
    margin: 0 !important;
    margin-bottom: 5px;
}

.VideoEditor-sub-navbar-container>.left-container>#sidebarbtn {
    cursor: pointer;
}

#tools-videoeditor-navbar {
    display: flex;
    flex-direction: row;
}