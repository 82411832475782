.VideoEditor-timeline-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 135px;
    background-color: rgb(15, 15, 15);
    border-right: 1px solid #fff;
}

.VideoEditor-timeline-sidebar #track-header {
    display: flex;
    cursor: pointer;
    /* padding-left: 5px; */
    align-items: center;
    border-bottom: 1px solid #fff;
    color: rgb(235, 237, 244);
}

.VideoEditor-timeline-sidebar #track-header span {
    margin-top: 0px;
    padding: 0.11rem;
    border-radius: 5px;
}

/* .VideoEditor-timeline-sidebar #track-header span:hover {
    background-color: rgb(238, 238, 238);
} */

.VideoEditor-timeline-sidebar>.track-list-container>hr {
    margin: 2px;
}

.VideoEditor-timeline-sidebar>.track-list-container> :first-child {
    color: rgb(238, 238, 238);
}

.VideoEditor-timeline-sidebar>.track-list-container> :first-child:hover {
    background-color: rgb(238, 238, 238);/*changed color*/
}

.VideoEditor-timeline-sidebar>.track-list-container> :last-child {
    color: rgb(238, 238, 238);/* changed color */
}

.VideoEditor-timeline-sidebar>.track-list-container> :last-child:hover {
    background-color: rgb(238, 238, 238);
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list {
    overflow-y: auto;
    padding: 0.06rem;
    font-size: 1rem;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    justify-items: center;
    border-bottom: 1px solid #fff;
    align-items: center;
    margin-top: 10px;
    /* margin: auto; */
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list::-webkit-scrollbar {
    display: none;
    -webkit-overflow-scrolling: touch;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul {
    list-style: none;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul>li {
    display: flex;
    justify-content: space-between;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul>li> :first-child {
    padding-left: 8px;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul>li:hover {
    color: rgb(106, 113, 125);
}

