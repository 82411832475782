#VideoEditorMain {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #151515;
    overflow: hidden;
}

#VideoEditorMain>.content {
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    width: 100vw;
    /* align-content: flex-start; */
    overflow-y: auto;
    box-sizing: border-box;
    padding: 16px 32px 20px;
    /* height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px; */
}

#VideoEditorMain>.content>.all-project-container {
    margin-top: 5%;
    margin-left: 5%;
    height: 50px;
    width:80%;
    /* justify-content: space-between; */
    
}
#VideoEditorMain>.content>.createProject {
    background: rgb(18, 19, 21);
    width:80%;
    height:100%;
    margin-left: 5%;
    padding: 32px 0px;
    
}


#VideoEditorMain>.content>.create-content-bar-open {
    min-height: 205px;
    padding: 1em;
    margin-top: 0.5em;
    background-color: rgba(0, 0, 0, 0.06);
}

#VideoEditorMain>.content>.create-content-bar-open .create-content-bar-open-item {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
    cursor: pointer;
}

#VideoEditorMain>.content>.create-content-bar-open .create-content-bar-open-item>img {
    height: 150px;
    width: 260px;
    border-radius: 5px;
}

#VideoEditorMain>.content>.create-content-bar {
    background: #dd2476;
    /* fallback for old browsers */
    border-radius: 40px;
    min-height: 190px;
    background-attachment: scroll;
    /* opacity: 0.7; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    color: white;
    z-index: 1;
}

#VideoEditorMain>.content>.create-content-bar>.item {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    cursor: pointer;
    text-shadow: 0 0 5px #000;
}

#VideoEditorMain>.content>.create-content-bar>.item>.icon {
    padding: 1em;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    width: max-content;
    border: 1px solid #fff;
}

#VideoEditorMain>.content>.create-content-bar>.item>.icon:hover,
#VideoEditorMain>.content>.create-content-bar>.item>.active {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}

.flickity-viewport {
    max-height: 322px;
}

.content {
    transition: width 0.3s;
    display: flex;
    flex-direction: column;
}
.startbutton{
    margin-left: 50%;
}

.home-header {
    padding-left: 20px;
    padding-top: 10px;
    font-size: 70px !important;
    line-height: 130px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 100px;
}

.home-content {
    margin-left: 100px;
}

#line1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(150, 150, 150);
    width: fit-content;
    padding-right: 10px;
    padding-left: 5px;
    border-radius: 5px;
}

#line1:hover {
    cursor: pointer;
    background-color: rgb(210, 210, 210);
}