.timeline-editor__frames-ruler {
    border-bottom: 1px solid rgb(248, 240, 240);
    color: rgb(120, 120, 120);
    font-size: 16px;
    height: 1.86em;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.timeline-editor__frames-ruler__second {
    /* border-right: 1px solid rgb(246, 235, 235);
    bottom: 0;
    height: 8px;
    position: absolute; */
    height: 3px;
    border-radius: 50%;
    background-color: antiquewhite;
}

.timeline-editor__frames-ruler__second:nth-of-type(1) {
    width: 3px;
    left: 20px;
}

.timeline-editor__frames-ruler__second:nth-of-type(2) {
    width: 3px;
    left: 40px;
}

.timeline-editor__frames-ruler__second:nth-of-type(3) {
    width: 3px;
    left: 60px;
}

.timeline-editor__frames-ruler__second:nth-of-type(4) {
    width: 3px;
    left: 80px;
}

.timeline-editor__frames-ruler__second:nth-of-type(5) {
    height: 6px;
    width: 6px;
    right: 0px;
    /* border-right: 1px solid rgb(246, 235, 235);
    bottom: 0;
    height: 8px;
    position: absolute; */
}

.timeline-editor__frames-ruler-s10s {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 100%;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100px;
    justify-content:space-around;
    align-items: center;
}

.timeline-editor__frames-ruler-s10s span {
    /* font-style: italic; */
    font-size: smaller;
}