* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#AssetLoaderUI {
  display: flex;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  
  align-items: center;
  justify-content: center;
}
#AssetLoaderUI > .loader {
  height: 25px;
  width: 25px;
  position: relative;
  animation: rotate 5s infinite;
}
#AssetLoaderUI > .loader > .ball {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
#AssetLoaderUI > .loader > .upperb {
  left: 0;
  top: -22px;
  background: #2da2ff;
  animation: upper 1s linear infinite;
}
@keyframes upper {
  50% {
    top: 22px;
  }
  100% {
    top: 22px;
  }
}
.rightt {
  right: -22px;
  top: 0;
  background: #ff337a;
  animation: right 1s linear infinite;
}
@keyframes right {
  50% {
    right: 22px;
  }
  100% {
    right: 22px;
  }
}
#AssetLoaderUI > .loader > .lowerr {
  bottom: -22px;
  left: 0;
  background: #ffff00;
  animation: r 1s linear infinite;
}
@keyframes r {
  50% {
    bottom: 22px;
  }
  100% {
    bottom: 22px;
  }
}
#AssetLoaderUI > .loader > .leftt {
  left: -22px;
  top: 0;
  background: #00ff00;
  animation: left 1s linear infinite;
}
@keyframes left {
  50% {
    left: 22px;
  }
  100% {
    left: 22px;
  }
}
