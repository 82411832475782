.context-menu {
  background-color: #000;
  border-radius: 3px;
  color: #fff;
  margin: 0;
  text-align: center;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 0 #000;
  z-index: 1;
}
.context-menu .list {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  transition: all 0.5s;
}
.context-menu .list:hover {
  background-color: #fff;
  color: #000;
}
