/* * {
    box-sizing: border-box;
} */

.upper {
    position: relative;
    /* width: max-content; */
    display: flex;
    flex-direction: column;
    user-select: none;
    -moz-user-select: none;
    width: 100%;
    /* padding: 5px; */
    /* padding-bottom: 2px; */
    place-items: center;
    justify-items: center;
}

.left {
    background: transparent;
    /* margin-left: auto; */
    flex-grow: 1;
}

.right {
    background: transparent;
    /* margin-left: auto; */
    flex-grow: 1;
    padding: 10px;
    place-items: center;
    justify-items: center;
    padding-bottom: 10px;
}

.middle {
    background-color: rgb(15, 15, 15);
    cursor: col-resize;
    width: 12px;
}

video::-webkit-media-controls-panel {
    background-image: none !important
}

.media-style {
    background-color: rgb(10, 10, 10);
}

/* style = {{width:"100%",height:"50px",backgroundColor:"black"}} */
.video-controlls {
    max-width: 100%;
    width: 99.8%;
    height: 45px;
    background-color: rgb(15,15,15);
    border-left: 1px solid rgb(50,50,50);
    border-bottom:1px solid rgb(50,50,50) ;
    /* margin: 4px;
    padding: 5px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    transition: all ease 0.5s;
}

.play_timing_controll {
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    padding: 0px 32px;
    height: 100%;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: column;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
}

.play_timing_controll_inner {
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
}

.play_timing_controll_start_time {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    user-select: none;
    text-align: initial;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}

.play_timing_controll_time_breaker {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    user-select: none;
    text-align: center;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}

.play_timing_controll_total_time {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    user-select: none;
    text-align: initial;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}

.play_back_controll {
    background-color: rgb(15,15,15);;
    color: white;
    height: 40px;
    width: 40px;
    margin: 5px;
    object-fit: fill;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    border: 1px solid rgb(100,100,100);
}

.controlsIcon--small {
    /* background: black; */
    display: flex;
    box-sizing: border-box;
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: rgb(15,15,15);;
    color: white;
    z-index: auto;
    cursor: pointer;
    padding: 8px 16px;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
}
.controlsIcon--small:hover{
    background-color: rgb(50,50,50);
}

.play_sound_controll {
    display: flex;
    box-sizing: border-box;
    /* min-width: initial;
    min-height: initial; */
    /* max-width: 220px; */
    /* max-height: none; */
    z-index: auto;
    /* height: 100%; */
    padding: 0px 16px;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    /* flex-grow: 1; */
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
}

.player__Volume {
    width: 60px;
    height: 5px;
    border: 0 none;
    overflow: visible;
    margin: 10px;
}

.player__slider {
    width: 60px;
    height: 5px;
    border: 0 none;
    overflow: visible;
    margin: 10px;
}

.play_back_speed_controll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}


.Playback_text {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    user-select: none;
    text-align: center;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}
.controlsIcon{
    display: flex;
    box-sizing: border-box;
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: rgb(15,15,15);;
    color: white;
    z-index: auto;
    cursor: pointer;
    padding: 8px 16px;
    -webkit-box-pack: center;
    justify-content: center;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    animation-duration: 500ms;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    animation-delay: 0ms;
    animation-iteration-count: 1;
    animation-direction: normal;
    animation-fill-mode: both;
    animation-play-state: running;
}
.controlsIcon:hover{
    background-color: rgb(50,50,50);
}

.Video-controlls-left{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Video-controlls-right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}