.timeline-editor__frames-layer__item {
  background-color: #faa;
  box-shadow: 0 0 1px black inset;
  box-sizing: border-box;
  color: #000;
  cursor: move;
  display: inline-block;
  left: 0;
  min-width: 10px;
  overflow-x: hidden;
  /* padding: 0.25em 0.5em; */
  position: absolute;
  top: 0;
  height: 100%;
  user-select: none;
  resize: horizontal;
  
}
.timeline-editor__frames-layer__item:after {
  margin: 0;
  content: ".";
  cursor: ew-resize;
  font-size: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 3px;
}
