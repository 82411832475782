@import url("https://fonts.googleapis.com/css2?family=Arvo:ital@1&display=swap");
.VideoEditor-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    font-size: 1.5rem;
    background-color: rgb(15,15,15);
}

.VideoEditor-navbar-container {
    height: 3rem;
}

.VideoEditor-navbar-title {
    font-family: "Arvo", serif;
    font-size: 30px;
    font-weight: bolder;
    color:white;
}

.VideoEditor-navbar-container>.left-container,
.VideoEditor-navbar-container>.right-container {
    display: flex;
    color: black;
    align-items: center;
}

.VideoEditor-navbar-container>.left-container span {
    color: black;
    cursor: pointer;
    padding-left: 10px;
}

.VideoEditor-navbar-container .right-container>#avatar {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: baseline;
}

.VideoEditor-navbar-container .right-container>#avatar>#user-tools {
    display: flex;
    cursor: pointer;
    position: absolute;
    display: none;
    top: 10px;
    justify-content: center;
    padding: 5px 0;
    background-color: rgba(255, 255, 255);
    width: 120px;
    box-shadow: 0 0 1px 0 #000;
    border-radius: 2px;
    z-index: 3;
    align-items: baseline;
}

.VideoEditor-navbar-container .right-container>#avatar:hover>#user-tools {
    display: flex;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}








.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  }
  
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    margin: 0% !important;
    /* width: 70vw; */
    justify-content:start;
    /* margin-right: 2rem; */
    padding-left: 25px;
  }
  
  .nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 80px; */
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
  }
  
  .nav-links:hover {
    background-color: #36373a;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  .NavBarMenuContent{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.6;
  }

  .submenuContent{
    animation: rotateMenu 400ms ease-in-out forwards;
    transform-origin: top center
   }