.VideoEditor-preview {
    /* height: 100%;
    width: 100%; */
    position: relative;
    margin: auto;
}

.VideoEditor-preview .media-style {
    position: absolute;
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
}

.VideoEditor-preview .media-style2 {
    position: absolute;
    /* left: 0;
    right: 0;
    bottom: 0; */
}

.VideoEditor-preview #custom-media-controls {
    position: absolute;
    /* left: 0;
    right: 0;
    bottom: 0; */
    height: 30px;
    border: 1px solid red;
}

#vid {
    margin: auto;
}