/* .grid-all-container {
  display: grid;
  grid-template-areas:
    "sidebar preview"
    "timeline timeline"; */


/* grid-auto-flow: column; */


/* background-color: #2196f3;
  max-width: 100vw;
  max-height: calc(100vh - 6.4rem);
  overflow: hidden;
} */


/* .sidebarGrid1 {
  grid-area: sidebar;
  width:766px;
}
.previewGrid2 {
  grid-area: preview;
}
.timelineGrid3 {
  grid-area: timeline;
} */

* {
  box-sizing: border-box;
}

.grid-container-all {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
}

#editor-page-container {
  width: 100%;
  height: 100vh;
}