* {
    box-sizing: border-box;
}

.lower {
    position: relative;
    display: flex;
    height: 100%;
    width: 100% !important;
    user-select: none;
    background: transparent;
    -moz-user-select: none;
    padding: 2px;
    padding-top: 0px;
    /* padding-right: 16px; */
}