.VideoEditor-sidebar-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; aman */
    height: calc(100vh - 3.2rem);
    padding-top: 10px;
    text-align: center;
    overflow: hidden;
    padding-bottom: 30px;
    z-index: 2;
    border-top-right-radius: 10px;
    background-color: rgb(255, 255, 255);
    transition: transform 1s ease-in-out;
}

#sidebar-button {
    display: "flex";
    flex-direction: "row";
    align-content: "center";
    justify-content: "center";
    color: rgb(150, 150, 150);
    padding: 0;
    font-size: 14;
}
.toggle_menu_btn{
    border-style: none;
    color: rgb(251, 242, 242);
    background-color: rgb(15, 15, 15);
    /* position:absolute;
    top: 50%;
    right: 0; */
    /* transform: translate(100%,-50%); */

}
#sidebarContainerMain{
    max-height:100vh;
}