.timeline-editor__frames {
    /* display: table-cell; */
    overflow-x: auto;
    vertical-align: top;
    height: 100%;
}

.timeline-editor__frames-layer {
    /* width: 100%; */
    border-bottom: 1px solid rgba(241, 238, 238, 1);
    height: 3.4rem;
    padding: 1px 0;
    position: relative;
}

.pointer-div {
    z-index: 200;
    position: absolute;
    transform: translate(0px);
    cursor: pointer;
    height: 100%;
}

.timeline {
    width: 100%;
    height: 100%;
}

.pointer {
    background-color: black;
    width: 3px;
    margin-left: 8px;
}