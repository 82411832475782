.card {
	transition: height 0.5s ease-in-out;
	will-change: height;
}
.text {
	padding: 10px;
	background-color: white;
}
/* .root{
	  borderRadius: "10px",
    // boxShadow: "2px 2px 2px 2px #888888"
    // transform:"translateY(-3em)",
    // transition:"all 0.3s ease 0s linear 0.3s zIndex 0s linear 0.1s",
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		transform: translateY(3em);
		transform: translateX(3em);
} */

.MenuRoot{
	flex-grow: 1;
	background-color: white;
	color:black;
	position: absolute;
	z-index: 100;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	animation: rotateMenu 100ms ease-in-out forwards;
  transform-origin: top center
}
    
@keyframes rotateMenu {
    0% {
        transform: rotateX(-90deg)
    }
    /* 70% {
        transform: rotateX(20deg) 
    }
    100% {
        transform: rotateX(0deg) 
    } */
}



/* 
// flexGrow: 1,
backgroundColor: "rgb(245,245,245)",
color: "black",
position: "absolute",
// top: "100%",
// left: 0,
// width:"1000px",
// height: "400px",
zIndex: "100",
// borderRadius: "10px",
// boxShadow: "2px 2px 2px 2px #888888"
// transform:"translateY(-3em)",
// transition:"all 0.3s ease 0s linear 0.3s zIndex 0s linear 0.1s",
borderBottomLeftRadius: "7px",
borderBottomRightRadius: "7px",
animation: "downOut 300ms ease-in-out forwards",
transformOrigin: "center center",
// transform: "translateY(3em)",
// transform: "translateX(3em)",
// transition: "all 0.3s ease 0s linear 0.3s z-index 0s linear 0.1s", */

.NavBarSubSubMenuName{
	color: black;
	width: 200px;
	font-size: 12px;
	font-weight: 520;
	padding-top: 5px;
	padding-bottom: 15px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	font-family:Arial, Helvetica, sans-serif;
	/* line-height: 0.676em; */
	letter-spacing: 0.013em;
	font-stretch: wider;
}

.NavBarSubSubMenuName:hover{
	background-color: rgb(200,200,200);
}