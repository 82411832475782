@import url(https://fonts.googleapis.com/css2?family=Arvo:ital@1&display=swap);
/* *{
  display:flex;
  justify-content:center;
  align-items:center;
} */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#AssetLoaderUI {
  display: flex;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  
  align-items: center;
  justify-content: center;
}
#AssetLoaderUI > .loader {
  height: 25px;
  width: 25px;
  position: relative;
  -webkit-animation: rotate 5s infinite;
          animation: rotate 5s infinite;
}
#AssetLoaderUI > .loader > .ball {
  position: absolute;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}
#AssetLoaderUI > .loader > .upperb {
  left: 0;
  top: -22px;
  background: #2da2ff;
  -webkit-animation: upper 1s linear infinite;
          animation: upper 1s linear infinite;
}
@-webkit-keyframes upper {
  50% {
    top: 22px;
  }
  100% {
    top: 22px;
  }
}
@keyframes upper {
  50% {
    top: 22px;
  }
  100% {
    top: 22px;
  }
}
.rightt {
  right: -22px;
  top: 0;
  background: #ff337a;
  -webkit-animation: right 1s linear infinite;
          animation: right 1s linear infinite;
}
@-webkit-keyframes right {
  50% {
    right: 22px;
  }
  100% {
    right: 22px;
  }
}
@keyframes right {
  50% {
    right: 22px;
  }
  100% {
    right: 22px;
  }
}
#AssetLoaderUI > .loader > .lowerr {
  bottom: -22px;
  left: 0;
  background: #ffff00;
  -webkit-animation: r 1s linear infinite;
          animation: r 1s linear infinite;
}
@-webkit-keyframes r {
  50% {
    bottom: 22px;
  }
  100% {
    bottom: 22px;
  }
}
@keyframes r {
  50% {
    bottom: 22px;
  }
  100% {
    bottom: 22px;
  }
}
#AssetLoaderUI > .loader > .leftt {
  left: -22px;
  top: 0;
  background: #00ff00;
  -webkit-animation: left 1s linear infinite;
          animation: left 1s linear infinite;
}
@-webkit-keyframes left {
  50% {
    left: 22px;
  }
  100% {
    left: 22px;
  }
}
@keyframes left {
  50% {
    left: 22px;
  }
  100% {
    left: 22px;
  }
}

.VideoEditor-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: 20px;
    font-size: 1.5rem;
    background-color: rgb(15,15,15);
}

.VideoEditor-navbar-container {
    height: 3rem;
}

.VideoEditor-navbar-title {
    font-family: "Arvo", serif;
    font-size: 30px;
    font-weight: bolder;
    color:white;
}

.VideoEditor-navbar-container>.left-container,
.VideoEditor-navbar-container>.right-container {
    display: flex;
    color: black;
    align-items: center;
}

.VideoEditor-navbar-container>.left-container span {
    color: black;
    cursor: pointer;
    padding-left: 10px;
}

.VideoEditor-navbar-container .right-container>#avatar {
    display: flex;
    cursor: pointer;
    position: relative;
    align-items: baseline;
}

.VideoEditor-navbar-container .right-container>#avatar>#user-tools {
    display: flex;
    cursor: pointer;
    position: absolute;
    display: none;
    top: 10px;
    justify-content: center;
    padding: 5px 0;
    background-color: rgba(255, 255, 255);
    width: 120px;
    box-shadow: 0 0 1px 0 #000;
    border-radius: 2px;
    z-index: 3;
    align-items: baseline;
}

.VideoEditor-navbar-container .right-container>#avatar:hover>#user-tools {
    display: flex;
}

.popup {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
}








.navbar {
    background: linear-gradient(90deg, rgb(28, 27, 27) 0%, rgb(26, 23, 23) 100%);
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  }
  
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    margin: 0% !important;
    /* width: 70vw; */
    justify-content:start;
    /* margin-right: 2rem; */
    padding-left: 25px;
  }
  
  .nav-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    /* height: 80px; */
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
  }
  
  .nav-links:hover {
    background-color: #36373a;
    border-radius: 4px;
    transition: all 0.2s ease-out;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }

  .NavBarMenuContent{
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    line-height: 1.6;
  }

  .submenuContent{
    -webkit-animation: rotateMenu 400ms ease-in-out forwards;
            animation: rotateMenu 400ms ease-in-out forwards;
    -webkit-transform-origin: top center;
            transform-origin: top center
   }
.card {
	transition: height 0.5s ease-in-out;
	will-change: height;
}
.text {
	padding: 10px;
	background-color: white;
}
/* .root{
	  borderRadius: "10px",
    // boxShadow: "2px 2px 2px 2px #888888"
    // transform:"translateY(-3em)",
    // transition:"all 0.3s ease 0s linear 0.3s zIndex 0s linear 0.1s",
		border-bottom-left-radius: 5px;
		border-bottom-right-radius: 5px;
		transform: translateY(3em);
		transform: translateX(3em);
} */

.MenuRoot{
	flex-grow: 1;
	background-color: white;
	color:black;
	position: absolute;
	z-index: 100;
	border-bottom-left-radius: 7px;
	border-bottom-right-radius: 7px;
	-webkit-animation: rotateMenu 100ms ease-in-out forwards;
	        animation: rotateMenu 100ms ease-in-out forwards;
  -webkit-transform-origin: top center;
          transform-origin: top center
}
    
@-webkit-keyframes rotateMenu {
    0% {
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg)
    }
    /* 70% {
        transform: rotateX(20deg) 
    }
    100% {
        transform: rotateX(0deg) 
    } */
}
    
@keyframes rotateMenu {
    0% {
        -webkit-transform: rotateX(-90deg);
                transform: rotateX(-90deg)
    }
    /* 70% {
        transform: rotateX(20deg) 
    }
    100% {
        transform: rotateX(0deg) 
    } */
}



/* 
// flexGrow: 1,
backgroundColor: "rgb(245,245,245)",
color: "black",
position: "absolute",
// top: "100%",
// left: 0,
// width:"1000px",
// height: "400px",
zIndex: "100",
// borderRadius: "10px",
// boxShadow: "2px 2px 2px 2px #888888"
// transform:"translateY(-3em)",
// transition:"all 0.3s ease 0s linear 0.3s zIndex 0s linear 0.1s",
borderBottomLeftRadius: "7px",
borderBottomRightRadius: "7px",
animation: "downOut 300ms ease-in-out forwards",
transformOrigin: "center center",
// transform: "translateY(3em)",
// transform: "translateX(3em)",
// transition: "all 0.3s ease 0s linear 0.3s z-index 0s linear 0.1s", */

.NavBarSubSubMenuName{
	color: black;
	width: 200px;
	font-size: 12px;
	font-weight: 520;
	padding-top: 5px;
	padding-bottom: 15px;
	padding-left: 10px;
	padding-right: 10px;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: flex-start;
	font-family:Arial, Helvetica, sans-serif;
	/* line-height: 0.676em; */
	letter-spacing: 0.013em;
	font-stretch: wider;
}

.NavBarSubSubMenuName:hover{
	background-color: rgb(200,200,200);
}
.VideoEditor-sub-navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 5vw;
    font-size: 1.5rem;
    background-color: rgb(15, 15, 15);
}

.VideoEditor-sub-navbar-container {
    background: rgb(15, 15, 15);
    height: 3rem;
    padding: 1.2rem 2rem;
    margin: 0 !important;
    margin-bottom: 5px;
}

.VideoEditor-sub-navbar-container>.left-container>#sidebarbtn {
    cursor: pointer;
}

#tools-videoeditor-navbar {
    display: flex;
    flex-direction: row;
}
.VideoEditor-sidebar-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; aman */
    height: calc(100vh - 3.2rem);
    padding-top: 10px;
    text-align: center;
    overflow: hidden;
    padding-bottom: 30px;
    z-index: 2;
    border-top-right-radius: 10px;
    background-color: rgb(255, 255, 255);
    transition: -webkit-transform 1s ease-in-out;
    transition: transform 1s ease-in-out;
    transition: transform 1s ease-in-out, -webkit-transform 1s ease-in-out;
}

#sidebar-button {
    display: "flex";
    flex-direction: "row";
    align-content: "center";
    justify-content: "center";
    color: rgb(150, 150, 150);
    padding: 0;
    font-size: 14;
}
.toggle_menu_btn{
    border-style: none;
    color: rgb(251, 242, 242);
    background-color: rgb(15, 15, 15);
    /* position:absolute;
    top: 50%;
    right: 0; */
    /* transform: translate(100%,-50%); */

}
#sidebarContainerMain{
    max-height:100vh;
}
.VideoEditor-side-all-stock-media {
    display: flex;
    overflow-y: scroll;
    height: 100%;
    background-color: rgb(15,15,15);
}

.VideoEditor-side-all-stock-media::-webkit-scrollbar {
    width: 6px;
}

.VideoEditor-side-all-stock-media::-webkit-scrollbar-track {
    background-color: rgb(15,15,15);
}

.VideoEditor-side-all-stock-media::-webkit-scrollbar-thumb {
    background-color: rgb(231, 223, 223);
}

#sidebar-weird-child-container {
    background-color: #ffffff;
}

#stock-media-sub-contianer-pics-videos {
    background-color: #f1f1f1;
}
.container{
    position: relative;
}
.vid{
    position:absolute;
    cursor: move;
}

.vid:hover{
    border: 1px solid #000;
}

.text{
    position:absolute;
    z-index: 500;
}



.container{
    position: relative;
    transition: ease-in-out;
}
.vid{
    position:absolute;
    cursor: move;
}

.vid:hover{
    border: 1px solid #000;
}

.text{
    position:absolute;
    z-index: 500;
}


.VideoEditor-sidebar2-container {
    height: calc(100vh - 3.2rem);
    width: 350px;
    padding-top: 10px;
    text-align: center;
    z-index: 2;
    overflow: hidden;
    border-top-right-radius: 10px;
    background-color: rgb(15, 15, 15);
}
.VideoEditor-side-container {
  height: 100%;
  background-color: rgb(15, 15, 15);
}


.VideoEditor-preview {
    /* height: 100%;
    width: 100%; */
    position: relative;
    margin: auto;
}

.VideoEditor-preview .media-style {
    position: absolute;
    /* top: 0;
    left: 0;
    right: 0;
    bottom: 0; */
}

.VideoEditor-preview .media-style2 {
    position: absolute;
    /* left: 0;
    right: 0;
    bottom: 0; */
}

.VideoEditor-preview #custom-media-controls {
    position: absolute;
    /* left: 0;
    right: 0;
    bottom: 0; */
    height: 30px;
    border: 1px solid red;
}

#vid {
    margin: auto;
}
.VideoEditor-timeline {
    display: flex;
    background-color: rgb(15, 15, 15);
    width: 100%;
    height: 30vh;
}

.VideoEditor-timeline-main-container {
    width: calc(100vw - 135px);
    overflow: auto;
}

.timeline-editor__frames::-webkit-scrollbar {
    height: 12px;
}

.timeline-editor__frames::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.timeline-editor__frames::-webkit-scrollbar-thumb {
    background-color: #a2a2a2;
}

.VideoEditor-timeline-main-container>#timeline-header {
    width: 100%;
    height: 30px;
    background-color: #fff;
    border-bottom: 1px solid #fff;
}

.VideoEditor-timeline-main-container>.track-list-container>hr {
    margin: 0;
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul {
    list-style: none;
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul>li {
    /* display: flex; */
    /* justify-content: space-between; */
    border-bottom: 1px solid rgb(196, 184, 184);
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul> :first-child {
    padding-top: 15px;
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul> :last-child {
    margin-bottom: 27px;
}
.VideoEditor-side-all-my-media {
    display: flex;
    overflow-y: scroll;
    height: 100%;
}

.VideoEditor-side-all-my-media::-webkit-scrollbar {
    width: 8px;
}

.VideoEditor-side-all-my-media::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.VideoEditor-side-all-my-media::-webkit-scrollbar-thumb {
    background-color: #a2a2a2;
}

.VideoEditor-side-container-my-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(226, 233, 238);
    width: 100%;
}

.VideoEditor-side-container-my-media>#drag-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    height: 100%;
    width: 100%;
}

.VideoEditor-side-container-my-media>#drag-area.drag-active {
    background-color: rgb(165, 241, 152) !important;
    border: 1px dashed black;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 5px;
    height: 100%;
    width: 100%;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery .img-con {
    display: flex;
    position: relative;
    margin-right: 5px;
    margin-bottom: 5px;
    height: -webkit-max-content;
    height: max-content;
    cursor: move;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery .img-con:hover {
    border: 1px solid #000;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery .img-con .im {
    height: 90px;
    width: 180px;
    object-fit: fill;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery>.img-con>#name {
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery>.img-con>#duration {
    top: 0;
    right: 0;
    color: #fff;
    padding: 0 4px;
    font-size: 0.8rem;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
}
.VideoEditor-side-container-subHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 2.3rem;
    padding: 0px 10px;
    background-color: rgb(255, 255, 255);
}
.context-menu {
  background-color: #000;
  border-radius: 3px;
  color: #fff;
  margin: 0;
  text-align: center;
  position: absolute;
  list-style: none;
  box-shadow: 0 0 0 #000;
  z-index: 1;
}
.context-menu .list {
  display: flex;
  align-items: center;
  padding: 5px 10px;
  cursor: pointer;
  border-bottom: 1px solid #fff;
  transition: all 0.5s;
}
.context-menu .list:hover {
  background-color: #fff;
  color: #000;
}

.VideoEditor-timeline-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 135px;
    background-color: rgb(15, 15, 15);
    border-right: 1px solid #fff;
}

.VideoEditor-timeline-sidebar #track-header {
    display: flex;
    cursor: pointer;
    /* padding-left: 5px; */
    align-items: center;
    border-bottom: 1px solid #fff;
    color: rgb(235, 237, 244);
}

.VideoEditor-timeline-sidebar #track-header span {
    margin-top: 0px;
    padding: 0.11rem;
    border-radius: 5px;
}

/* .VideoEditor-timeline-sidebar #track-header span:hover {
    background-color: rgb(238, 238, 238);
} */

.VideoEditor-timeline-sidebar>.track-list-container>hr {
    margin: 2px;
}

.VideoEditor-timeline-sidebar>.track-list-container> :first-child {
    color: rgb(238, 238, 238);
}

.VideoEditor-timeline-sidebar>.track-list-container> :first-child:hover {
    background-color: rgb(238, 238, 238);/*changed color*/
}

.VideoEditor-timeline-sidebar>.track-list-container> :last-child {
    color: rgb(238, 238, 238);/* changed color */
}

.VideoEditor-timeline-sidebar>.track-list-container> :last-child:hover {
    background-color: rgb(238, 238, 238);
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list {
    overflow-y: auto;
    padding: 0.06rem;
    font-size: 1rem;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
    justify-items: center;
    border-bottom: 1px solid #fff;
    align-items: center;
    margin-top: 10px;
    /* margin: auto; */
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list::-webkit-scrollbar {
    display: none;
    -webkit-overflow-scrolling: touch;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul {
    list-style: none;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul>li {
    display: flex;
    justify-content: space-between;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul>li> :first-child {
    padding-left: 8px;
}

.VideoEditor-timeline-sidebar>.track-list-container .track-list>ul>li:hover {
    color: rgb(106, 113, 125);
}


.timeline-editor__frames {
    /* display: table-cell; */
    overflow-x: auto;
    vertical-align: top;
    height: 100%;
}

.timeline-editor__frames-layer {
    /* width: 100%; */
    border-bottom: 1px solid rgba(241, 238, 238, 1);
    height: 3.4rem;
    padding: 1px 0;
    position: relative;
}

.pointer-div {
    z-index: 200;
    position: absolute;
    -webkit-transform: translate(0px);
            transform: translate(0px);
    cursor: pointer;
    height: 100%;
}

.timeline {
    width: 100%;
    height: 100%;
}

.pointer {
    background-color: black;
    width: 3px;
    margin-left: 8px;
}
.timeline-editor__frames-layer__item {
  background-color: #faa;
  box-shadow: 0 0 1px black inset;
  box-sizing: border-box;
  color: #000;
  cursor: move;
  display: inline-block;
  left: 0;
  min-width: 10px;
  overflow-x: hidden;
  /* padding: 0.25em 0.5em; */
  position: absolute;
  top: 0;
  height: 100%;
  -webkit-user-select: none;
          user-select: none;
  resize: horizontal;
  
}
.timeline-editor__frames-layer__item:after {
  margin: 0;
  content: ".";
  cursor: ew-resize;
  font-size: 0;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 3px;
}

.timeline-editor__frames-ruler {
    border-bottom: 1px solid rgb(248, 240, 240);
    color: rgb(120, 120, 120);
    font-size: 16px;
    height: 1.86em;
    min-width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.timeline-editor__frames-ruler__second {
    /* border-right: 1px solid rgb(246, 235, 235);
    bottom: 0;
    height: 8px;
    position: absolute; */
    height: 3px;
    border-radius: 50%;
    background-color: antiquewhite;
}

.timeline-editor__frames-ruler__second:nth-of-type(1) {
    width: 3px;
    left: 20px;
}

.timeline-editor__frames-ruler__second:nth-of-type(2) {
    width: 3px;
    left: 40px;
}

.timeline-editor__frames-ruler__second:nth-of-type(3) {
    width: 3px;
    left: 60px;
}

.timeline-editor__frames-ruler__second:nth-of-type(4) {
    width: 3px;
    left: 80px;
}

.timeline-editor__frames-ruler__second:nth-of-type(5) {
    height: 6px;
    width: 6px;
    right: 0px;
    /* border-right: 1px solid rgb(246, 235, 235);
    bottom: 0;
    height: 8px;
    position: absolute; */
}

.timeline-editor__frames-ruler-s10s {
    display: flex;
    flex-direction: row;
    font-size: 14px;
    height: 100%;
    padding: 0;
    position: relative;
    text-align: center;
    width: 100px;
    justify-content:space-around;
    align-items: center;
}

.timeline-editor__frames-ruler-s10s span {
    /* font-style: italic; */
    font-size: smaller;
}
.VideoEditor-main-sidebar {
    display: flex;
    flex-direction: column;
    min-width: 200px;
    padding: 10px;
    height: calc(100vh - 4rem);
    background-color: #fff;
    border-right: solid 2px rgba(255, 255, 255, 0.57);
}

.VideoEditor-main-sidebar>#create-list-btn {
    text-align: center;
}

.VideoEditor-main-sidebar>#create-list-btn>#creator-list-btn-item {
    background-color: #fff;
    border-radius: 5px;
    display: none;
    position: absolute;
    left: 24px;
    width: 152px;
    flex-direction: column;
    box-shadow: 0 0 1px 0 #000;
}

.VideoEditor-main-sidebar>#create-list-btn>#creator-list-btn-item>#item {
    background-color: #fff;
    color: #000;
    display: flex;
    cursor: pointer;
    padding: 5px;
    justify-content: space-evenly;
    transition: all 0.3s;
}

.VideoEditor-main-sidebar>#create-list-btn>#creator-list-btn-item>#item:hover {
    background-color: #000;
    color: #fff;
}

.VideoEditor-main-sidebar>#create-list-btn:hover>#creator-list-btn-item {
    display: flex;
}

.VideoEditor-main-sidebar>#creator-list-func {
    font-size: 20px;
    color: black;
}

.VideoEditor-main-sidebar>#creator-list-func>#creator-list-func-item {
    cursor: pointer;
    padding: 5px;
    transition: linear 0.3s;
}

.VideoEditor-main-sidebar>#creator-list-func>#creator-list-func-item>#item> :first-child {
    margin-right: 10px;
}

.VideoEditor-main-sidebar>#creator-list-func>#creator-list-func-item:hover {
    background-color: #000;
    color: #fff;
}
.VideoEditor-side-container-subSidebar {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-width: 180px;
    padding: 0px 10px;
    color: aliceblue;
    background-color: #f3f3f3;
}

.MuiTypography-colorTextSecondary {
    color: white !important;
}


/* * {
    box-sizing: border-box;
} */

.upper {
    position: relative;
    /* width: max-content; */
    display: flex;
    flex-direction: column;
    -webkit-user-select: none;
            user-select: none;
    -moz-user-select: none;
    width: 100%;
    /* padding: 5px; */
    /* padding-bottom: 2px; */
    place-items: center;
    justify-items: center;
}

.left {
    background: transparent;
    /* margin-left: auto; */
    flex-grow: 1;
}

.right {
    background: transparent;
    /* margin-left: auto; */
    flex-grow: 1;
    padding: 10px;
    place-items: center;
    justify-items: center;
    padding-bottom: 10px;
}

.middle {
    background-color: rgb(15, 15, 15);
    cursor: col-resize;
    width: 12px;
}

video::-webkit-media-controls-panel {
    background-image: none !important
}

.media-style {
    background-color: rgb(10, 10, 10);
}

/* style = {{width:"100%",height:"50px",backgroundColor:"black"}} */
.video-controlls {
    max-width: 100%;
    width: 99.8%;
    height: 45px;
    background-color: rgb(15,15,15);
    border-left: 1px solid rgb(50,50,50);
    border-bottom:1px solid rgb(50,50,50) ;
    /* margin: 4px;
    padding: 5px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    transition: all ease 0.5s;
}

.play_timing_controll {
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    padding: 0px 32px;
    height: 100%;
    justify-content: center;
    flex-direction: column;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}

.play_timing_controll_inner {
    display: flex;
    box-sizing: border-box;
    min-width: initial;
    min-height: initial;
    max-width: none;
    max-height: none;
    z-index: auto;
    flex-direction: row;
    align-items: center;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}

.play_timing_controll_start_time {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    -webkit-user-select: none;
            user-select: none;
    text-align: initial;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}

.play_timing_controll_time_breaker {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    -webkit-user-select: none;
            user-select: none;
    text-align: center;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}

.play_timing_controll_total_time {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    -webkit-user-select: none;
            user-select: none;
    text-align: initial;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}

.play_back_controll {
    background-color: rgb(15,15,15);;
    color: white;
    height: 40px;
    width: 40px;
    margin: 5px;
    object-fit: fill;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    border: 1px solid rgb(100,100,100);
}

.controlsIcon--small {
    /* background: black; */
    display: flex;
    box-sizing: border-box;
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: rgb(15,15,15);;
    color: white;
    z-index: auto;
    cursor: pointer;
    padding: 8px 16px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}
.controlsIcon--small:hover{
    background-color: rgb(50,50,50);
}

.play_sound_controll {
    display: flex;
    box-sizing: border-box;
    /* min-width: initial;
    min-height: initial; */
    /* max-width: 220px; */
    /* max-height: none; */
    z-index: auto;
    /* height: 100%; */
    padding: 0px 16px;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    -webkit-box-flex: 1;
    /* flex-grow: 1; */
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}

.player__Volume {
    width: 60px;
    height: 5px;
    border: 0 none;
    overflow: visible;
    margin: 10px;
}

.player__slider {
    width: 60px;
    height: 5px;
    border: 0 none;
    overflow: visible;
    margin: 10px;
}

.play_back_speed_controll {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
}


.Playback_text {
    font-size: 12px;
    font-family: Inter;
    line-height: 16px;
    color: rgb(160, 174, 192);
    font-weight: 700;
    word-break: break-word;
    -webkit-user-select: none;
            user-select: none;
    text-align: center;
    max-width: 100%;
    max-height: 100%;
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    margin: 2px;
}
.controlsIcon{
    display: flex;
    box-sizing: border-box;
    border: none;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background-color: rgb(15,15,15);;
    color: white;
    z-index: auto;
    cursor: pointer;
    padding: 8px 16px;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    -webkit-animation-duration: 500ms;
            animation-duration: 500ms;
    -webkit-animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
            animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
    -webkit-animation-delay: 0ms;
            animation-delay: 0ms;
    -webkit-animation-iteration-count: 1;
            animation-iteration-count: 1;
    -webkit-animation-direction: normal;
            animation-direction: normal;
    -webkit-animation-fill-mode: both;
            animation-fill-mode: both;
    -webkit-animation-play-state: running;
            animation-play-state: running;
}
.controlsIcon:hover{
    background-color: rgb(50,50,50);
}

.Video-controlls-left{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.Video-controlls-right{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
* {
    box-sizing: border-box;
}

.lower {
    position: relative;
    display: flex;
    height: 100%;
    width: 100% !important;
    -webkit-user-select: none;
            user-select: none;
    background: transparent;
    -moz-user-select: none;
    padding: 2px;
    padding-top: 0px;
    /* padding-right: 16px; */
}
/* .grid-all-container {
  display: grid;
  grid-template-areas:
    "sidebar preview"
    "timeline timeline"; */


/* grid-auto-flow: column; */


/* background-color: #2196f3;
  max-width: 100vw;
  max-height: calc(100vh - 6.4rem);
  overflow: hidden;
} */


/* .sidebarGrid1 {
  grid-area: sidebar;
  width:766px;
}
.previewGrid2 {
  grid-area: preview;
}
.timelineGrid3 {
  grid-area: timeline;
} */

* {
  box-sizing: border-box;
}

.grid-container-all {
  width: 100%;
  display: flex;
  flex-direction: row;
  height: 100%;
}

#editor-page-container {
  width: 100%;
  height: 100vh;
}
*{
  transition: display 1s ease-in-out;
}
.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  border-right: #dddddd;
  background-color: #151515;
}
#create-list-btn {
  text-align: center;
}
#create-list-btn > #creator-list-btn-item {
  background-color: #fff;
  border-radius: 5px;
  display: none;
  position: absolute;
  left: 24px;
  width: 152px;
  flex-direction: column;
  box-shadow: 0 0 1px 0 #000;
}
#create-list-btn > #creator-list-btn-item > #item {
  background-color: #fff;
  color: #000;
  display: flex;
  cursor: pointer;
  padding: 5px;
  justify-content: space-evenly;
  transition: all 0.3s;
}
#create-list-btn:hover > #creator-list-btn-item {
  display: flex;
}
#new-project {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(170, 169, 165);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding-inline: 20px;
  -webkit-padding-start: 16px;
          padding-inline-start: 16px;
  padding-bottom: 12px;
}

#new-project:hover {
  cursor: pointer;
  background-color: #151515;
}

#for-margin {
  width: 150px;
  margin-bottom: 12px;
  border-top: 1px solid #dddddd;
}

#VideoEditorMain {
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    background-color: #151515;
    overflow: hidden;
}

#VideoEditorMain>.content {
    /* grid-template-columns: repeat(auto-fit, minmax(300px, 1fr)); */
    width: 100vw;
    /* align-content: flex-start; */
    overflow-y: auto;
    box-sizing: border-box;
    padding: 16px 32px 20px;
    /* height: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px; */
}

#VideoEditorMain>.content>.all-project-container {
    margin-top: 5%;
    margin-left: 5%;
    height: 50px;
    width:80%;
    /* justify-content: space-between; */
    
}
#VideoEditorMain>.content>.createProject {
    background: rgb(18, 19, 21);
    width:80%;
    height:100%;
    margin-left: 5%;
    padding: 32px 0px;
    
}


#VideoEditorMain>.content>.create-content-bar-open {
    min-height: 205px;
    padding: 1em;
    margin-top: 0.5em;
    background-color: rgba(0, 0, 0, 0.06);
}

#VideoEditorMain>.content>.create-content-bar-open .create-content-bar-open-item {
    min-height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-right: 1em;
    cursor: pointer;
}

#VideoEditorMain>.content>.create-content-bar-open .create-content-bar-open-item>img {
    height: 150px;
    width: 260px;
    border-radius: 5px;
}

#VideoEditorMain>.content>.create-content-bar {
    background: #dd2476;
    /* fallback for old browsers */
    border-radius: 40px;
    min-height: 190px;
    background-attachment: scroll;
    /* opacity: 0.7; */
    display: flex;
    align-items: center;
    justify-content: space-around;
    position: relative;
    color: white;
    z-index: 1;
}

#VideoEditorMain>.content>.create-content-bar>.item {
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    align-items: center;
    font-size: 0.8rem;
    cursor: pointer;
    text-shadow: 0 0 5px #000;
}

#VideoEditorMain>.content>.create-content-bar>.item>.icon {
    padding: 1em;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.7);
    width: -webkit-max-content;
    width: max-content;
    border: 1px solid #fff;
}

#VideoEditorMain>.content>.create-content-bar>.item>.icon:hover,
#VideoEditorMain>.content>.create-content-bar>.item>.active {
    background-color: #fff;
    border: 1px solid #000;
    color: #000;
}

.flickity-viewport {
    max-height: 322px;
}

.content {
    transition: width 0.3s;
    display: flex;
    flex-direction: column;
}
.startbutton{
    margin-left: 50%;
}

.home-header {
    padding-left: 20px;
    padding-top: 10px;
    font-size: 70px !important;
    line-height: 130px;
    font-weight: 700;
    color: rgb(255, 255, 255);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-bottom: 100px;
}

.home-content {
    margin-left: 100px;
}

#line1 {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: rgb(150, 150, 150);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    padding-right: 10px;
    padding-left: 5px;
    border-radius: 5px;
}

#line1:hover {
    cursor: pointer;
    background-color: rgb(210, 210, 210);
}
.Navbaredits_leftsidebar{
  display: flex;
  flex-direction: row;
  /* margin-bottom: 0px; */
}
/* style={{ width: "400px", height: "85vh", overflowY: "scroll", backgroundColor: "rgb(15,15,15)" }} */

/* style={{ fontSize: "20px", backgroundColor: "rgb(30,30,30)", margin: "5px", color: "white" }} */

/* style={{ width: "2000px", height: "85vh", overflowY: "scroll" }}{} */

.Navbaredits_rightsidebar{
  width: 70%;
  height: 78vh;
  background-color: bisque;
  margin:10px;
  margin-bottom: 0px;
  border: 1px solid rgb(15,15,15) ;
}
/* .Navbaredits_rightsidebar::-webkit-scrollbar {
  display: none;
}

.Navbaredits_rightsidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
 /* BorderRadius: "10px", color: "white" }} */
.Navbaredits_rightsidebar_content{
  width:60%;
  /* padding: 3px; */
  /* background-color: grey; */
  margin-left: 40px;
  margin-top: 2px;
  border-radius: 5px;
  color: white;
  margin-bottom: 0px;


}
.Navbarsublevels{
  display:flex;
  flex-direction: row;
}

.addfeildButton{
  border: 1px solid white;
  border-radius: 5px;
  background-color: rgb(15,15,15);
  color: white;
  width: 100px;
  height: 30px;
  margin: auto;
  margin-left :5px;
  margin-right: 5px;
}
.loaderUI{
  background:none;
  position: fixed;
  right:50%;
  top:50%;
  z-index: 1000;
}
.MenuStructName{
  width:100%;
  height: 10%;
  background-color: rgb(15,15,15);
  border: 10px solid rgb(15,15,15) ;
  color: white;
}
.MenuStructHeader{
  /* width: 50%;
  position: fixed; */
  display: flex;
  flex-direction: row;
  background-color: rgb(15,15,15);
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.MenuScrollable{
  overflow-y: scroll;
  width: 100%;
  height: 91%;
}

.MenuScrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.MenuScrollable {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
.Navbaredits_leftsidebar_content{
  width:25%;
  height: 78vh;
  
  background-color: rgb(15,15,15);
  margin:10px;
  padding: 10px;
  margin-bottom: 0px;
}

.Navbaredits_leftsidebar_name{
  font-size: 15px;
  background-color: rgb(50,50,50);
  margin: 2px;
  color: white;
  padding: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.NavBarEditsName{
  display:flex;
  flex-direction: column;
  margin: 2px;
}

.ModifyFeildInput{
  width:90%;
  color:black;
  background-color: white;
  left:5%;
}

.FeildsListName{
  overflow-y: scroll;
  width: 100%;
  height: 50%;
  background-color: rgb(90,90,90);
}

.FeildsListName::-webkit-scrollbar {
  display: none;
}

.FeildsListName {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
.real_submenu{
  width: 70%;
  height: 40px;
}
.dummy_submenu{
  width: 20%;
  height: 40px;
  /* background-color: aqua; */
}
.Navbaredits_rightsidebar_opt1{
  background-color: rgb(15,15,15);
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}
html::-webkit-scrollbar {
    display: none;
    -webkit-overflow-scrolling: touch;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "Nunito", sans-serif;
}

img {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}


/* login signup css */

.loginForm {
    padding: 20px 20px 10px 20px;
    max-width: 502px;
    margin-top: 30px;
}

.loginForm a {
    text-decoration: none;
    font-weight: 700;
    color: rgb(230, 86, 19);
    font-size: 1rem !important;
}

.loginGrid {
    height: 100vh;
    padding: 30px 5px;
    background-color: #000;
}

@media only screen and (max-width: 413px) {
    .loginGrid {
        height: 100%;
    }
}


/* end */

.login {
    width: 100%;
    min-height: 100vh;
    min-width: 520px;
    padding: 0 20px;
    background: #e9e9e9;
    display: flex;
}

.login .loginContainer {
    padding: 60px;
    margin: auto;
    width: 100%;
    max-width: 520px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
    box-shadow: 0 50px 70px -20px rgba(0, 0, 0, 0.8);
}

.login .loginContainer label {
    color: white;
    margin: 14px 0;
    display: block;
    font-size: 22px;
    line-height: 1;
}

.login .loginContainer input {
    width: 100%;
    border: none;
    outline: none;
    font-size: 19px;
    padding: 10px 40px 10px 10px;
    background: rgba(255, 255, 255, 0.1);
    color: #fff;
    letter-spacing: 1px;
}

.login .loginContainer h1 {
    text-align: center;
    color: #fff;
}

.login .loginContainer .btnContainer {
    width: 100%;
    padding: 24px 0;
}

.login .loginContainer .btnContainer p {
    margin: 14px 0 0 0;
    text-align: right;
    color: #fff;
}

.login .loginContainer .btnContainer p span {
    color: yellow;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-left: 5px;
    cursor: pointer;
    transition: all 400ms ease-in-out;
}

.login .loginContainer .errorMsg {
    margin-top: 15px;
    color: red;
    font-size: 16px;
}

.home {
    width: 100%;
    min-height: 100vh;
    /* background: radial-gradient(
    ellipse at left bottom,
    rgba(22, 24, 47, 1) 0%,
    rgba(38, 20, 72, 0.9) 59%,
    rgba(17, 27, 75, 0.9) 100%
  ); */
    background: black;
}

.navbar {
    padding: 0;
    position: fixed;
    width: 100vw;
    z-index: 22;
    color: black;
    margin-bottom: 250px;
}

.navbar a {
    position: absolute;
    left: 8%;
    color: #fff;
    font-size: 1.5rem !important;
}

.navbar a:hover {
    text-decoration: none;
    color: #c04848;
}

.navbar nav {
    background: #603bbb;
    width: 100%;
    padding: 8px 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbar nav button {
    width: 86px;
    padding: 8px 0;
    background: #3a2b58;
}


/* Profile picture */

#user-name {
    position: relative;
    left: 60%;
}


/* #user-name div .name {
  border: 1px solid black;
} */

#user-avatar {
    position: relative;
    left: 15%;
}

#avatar {
    margin-top: -10px !important;
    height: 20px;
    width: 85px;
    border-radius: 50%;
}

.video-model {
    height: 50vh;
    width: 50vh;
}


/* home category name */

.category-name {
    margin-left: 10px;
    color: white;
    -webkit-user-select: none;
            user-select: none;
    font-weight: 900;
    font-size: 1.2rem;
}

.home-content {
    padding-top: 30px;
}

.user-profile {
    height: 200px;
}

.loginContainer .user-profile img {
    position: relative;
    left: 90px;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    background-color: #ffffff;
    border: 1px solid red;
}

.loginContainer .user-profile input {
    position: relative;
    top: -71px;
    left: -110px;
    border-radius: 50%;
    height: 200px;
    width: 200px;
    /* background-color: transparent; */
    opacity: 0;
    z-index: 2;
    cursor: pointer;
}

#profile-progress {
    width: 130%;
    position: relative;
    top: -69px;
    left: -60px;
}


/* #profile-progress #progress-bar {
  width: 100%;
  position: relative;
  top: 22px;
} */


/* #profile-progress p {
  position: relative;
  text-align: center;
  top: -6px;
} */

.user-profile button {
    position: relative;
    bottom: 125px;
    left: 320px;
    width: 80px;
}

.profileContainer {
    margin-top: 75px !important;
    padding: 13px 60px !important;
}

.video-progress {
    position: relative;
    top: -16px;
}

.thumbnail-progress {
    position: relative;
    top: -16px;
}

#modal-thumbnail {
    height: 100px;
}

.text-success {
    display: flex;
    margin: auto;
}

.text-primary {
    display: flex;
    margin: auto;
}

hr {
    border-top: 1px solid rgba(255, 255, 255, 0.57) !important;
}

.main-carousel {
    cursor: pointer;
    width: 80%;
    margin: 0 auto;
}

.img:after {
    content: "\A";
    position: absolute;
    width: 100%;
    height: 30%;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 1));
    /* box-shadow: 15px 0px 100px 50px black inset; */
    /* background:rgba(0,0,0,0.6); */
    /* opacity: 1; */
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
}

@media only screen and (max-width: 522px) {
    #signUpName {
        width: 100%;
    }
    .main-carousel {
        margin-top: 50px;
        width: 100%;
    }
    .margin-top {
        margin-top: 50px;
    }
    .carousel-control-next,
    .carousel-control-prev {
        top: 35px !important;
    }
}

input[type="time"]::-webkit-datetime-edit-ampm-field {
    display: none;
}

* {
    box-sizing: border-box;
}


/* .flickity-enabled {
  top: 4rem !important;
} */

.header-container {
    color: #000000;
    background-color: #fff;
    border-bottom: 1px solid #23b5b5;
    width: 100%;
    height: 4rem;
    /* position: fixed; */
    top: 0;
    left: 0;
    z-index: 3;
    display: flex;
    align-items: center;
    padding: 0% 1%;
    margin-bottom: 2vw;
}

.cat {
    padding: 0% 1%;
    color: #000;
    transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    -webkit-transition: color 1s cubic-bezier(0.165, 0.84, 0.44, 1);
    will-change: color;
}

.cat a {
    color: #000;
}

.cat:hover {
    cursor: pointer;
}

.cat a:hover {
    color: #c7c7c7;
    text-decoration: none;
}

.exp-text {
    margin-left: 150px;
    margin-right: 25px;
    font-size: 1.3rem;
    padding: 0% 1% 0% 1%;
}

.exp-text a:first-child {
    margin-right: 1vw;
}

.exp-text a {
    text-decoration: none;
    color: inherit;
}

.to-right {
    display: flex;
    align-items: center;
    position: fixed;
    right: 100px;
    float: right;
}

.to-right .explified .logout-button {
    display: none;
    position: fixed;
    color: #23b5b5 !important;
    background-color: #3a2b58 !important;
}

.to-right .explified:hover .logout-button {
    display: block;
}

.user-search input {
    outline: none;
    border: 0;
    /* height: 1.2rem; */
    background: transparent;
    width: 200px;
    font-size: 0.9rem;
    /* color: #ffffff; */
    border-radius: 5px;
    padding: 4px 16px 4px 5px;
    transition: all 1s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.user-search input:focus,
.user-search input:hover {
    border: 1.5px solid #23b5b5;
}

.allow-pad {
    padding: 0px 25px;
}

.exp-text:hover,
.explified:hover,
.exp-logo:hover {
    cursor: pointer;
}

.exp-text a:hover {
    text-decoration: none;
    color: inherit;
}

.menu {
    background-color: transparent;
    border: none;
    cursor: pointer;
    width: 30px;
    height: 30px;
    display: none;
    /* padding: 0% 1%; */
    outline: none;
}

.line {
    fill: none;
    stroke: #ffffff;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1), stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}

.line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
}

.line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
}

.opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

.opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
}

.opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
}

@media only screen and (max-width: 860px) {
    .menu {
        display: block;
    }
    .cat {
        display: none;
    }
    .explified {
        display: none;
    }
    .logout-but {
        display: none;
    }
}


/* Phone Input css overriding */

.react-tel-input .form-control {
    width: 100%;
    height: 56px;
}
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
