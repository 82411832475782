.container{
    position: relative;
    transition: ease-in-out;
}
.vid{
    position:absolute;
    cursor: move;
}

.vid:hover{
    border: 1px solid #000;
}

.text{
    position:absolute;
    z-index: 500;
}

