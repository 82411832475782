.sidebar-bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: fit-content;
  border-right: #dddddd;
  background-color: #151515;
}
#create-list-btn {
  text-align: center;
}
#create-list-btn > #creator-list-btn-item {
  background-color: #fff;
  border-radius: 5px;
  display: none;
  position: absolute;
  left: 24px;
  width: 152px;
  flex-direction: column;
  box-shadow: 0 0 1px 0 #000;
}
#create-list-btn > #creator-list-btn-item > #item {
  background-color: #fff;
  color: #000;
  display: flex;
  cursor: pointer;
  padding: 5px;
  justify-content: space-evenly;
  transition: all 0.3s;
}
#create-list-btn:hover > #creator-list-btn-item {
  display: flex;
}
#new-project {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: rgb(170, 169, 165);
  width: fit-content;
  padding-inline: 20px;
  padding-inline-start: 16px;
  padding-bottom: 12px;
}

#new-project:hover {
  cursor: pointer;
  background-color: #151515;
}

#for-margin {
  width: 150px;
  margin-bottom: 12px;
  border-top: 1px solid #dddddd;
}
