.Navbaredits_leftsidebar{
  display: flex;
  flex-direction: row;
  /* margin-bottom: 0px; */
}
/* style={{ width: "400px", height: "85vh", overflowY: "scroll", backgroundColor: "rgb(15,15,15)" }} */

/* style={{ fontSize: "20px", backgroundColor: "rgb(30,30,30)", margin: "5px", color: "white" }} */

/* style={{ width: "2000px", height: "85vh", overflowY: "scroll" }}{} */

.Navbaredits_rightsidebar{
  width: 70%;
  height: 78vh;
  background-color: bisque;
  margin:10px;
  margin-bottom: 0px;
  border: 1px solid rgb(15,15,15) ;
}
/* .Navbaredits_rightsidebar::-webkit-scrollbar {
  display: none;
}

.Navbaredits_rightsidebar {
  -ms-overflow-style: none;
  scrollbar-width: none;
} */
 /* BorderRadius: "10px", color: "white" }} */
.Navbaredits_rightsidebar_content{
  width:60%;
  /* padding: 3px; */
  /* background-color: grey; */
  margin-left: 40px;
  margin-top: 2px;
  border-radius: 5px;
  color: white;
  margin-bottom: 0px;


}
.Navbarsublevels{
  display:flex;
  flex-direction: row;
}

.addfeildButton{
  border: 1px solid white;
  border-radius: 5px;
  background-color: rgb(15,15,15);
  color: white;
  width: 100px;
  height: 30px;
  margin: auto;
  margin-left :5px;
  margin-right: 5px;
}
.loaderUI{
  background:none;
  position: fixed;
  right:50%;
  top:50%;
  z-index: 1000;
}
.MenuStructName{
  width:100%;
  height: 10%;
  background-color: rgb(15,15,15);
  border: 10px solid rgb(15,15,15) ;
  color: white;
}
.MenuStructHeader{
  /* width: 50%;
  position: fixed; */
  display: flex;
  flex-direction: row;
  background-color: rgb(15,15,15);
  justify-content: space-between;
  align-items: center;
  padding: 5px;
}

.MenuScrollable{
  overflow-y: scroll;
  width: 100%;
  height: 91%;
}

.MenuScrollable::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.MenuScrollable {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}