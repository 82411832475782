.VideoEditor-timeline {
    display: flex;
    background-color: rgb(15, 15, 15);
    width: 100%;
    height: 30vh;
}

.VideoEditor-timeline-main-container {
    width: calc(100vw - 135px);
    overflow: auto;
}

.timeline-editor__frames::-webkit-scrollbar {
    height: 12px;
}

.timeline-editor__frames::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.timeline-editor__frames::-webkit-scrollbar-thumb {
    background-color: #a2a2a2;
}

.VideoEditor-timeline-main-container>#timeline-header {
    width: 100%;
    height: 30px;
    background-color: #fff;
    border-bottom: 1px solid #fff;
}

.VideoEditor-timeline-main-container>.track-list-container>hr {
    margin: 0;
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul {
    list-style: none;
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul>li {
    /* display: flex; */
    /* justify-content: space-between; */
    border-bottom: 1px solid rgb(196, 184, 184);
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul> :first-child {
    padding-top: 15px;
}

.VideoEditor-timeline-main-container>.track-list-container .track-list>ul> :last-child {
    margin-bottom: 27px;
}