.real_submenu{
  width: 70%;
  height: 40px;
}
.dummy_submenu{
  width: 20%;
  height: 40px;
  /* background-color: aqua; */
}
.Navbaredits_rightsidebar_opt1{
  background-color: rgb(15,15,15);
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  height: 40px;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}