.VideoEditor-side-all-stock-media {
    display: flex;
    overflow-y: scroll;
    height: 100%;
    background-color: rgb(15,15,15);
}

.VideoEditor-side-all-stock-media::-webkit-scrollbar {
    width: 6px;
}

.VideoEditor-side-all-stock-media::-webkit-scrollbar-track {
    background-color: rgb(15,15,15);
}

.VideoEditor-side-all-stock-media::-webkit-scrollbar-thumb {
    background-color: rgb(231, 223, 223);
}

#sidebar-weird-child-container {
    background-color: #ffffff;
}

#stock-media-sub-contianer-pics-videos {
    background-color: #f1f1f1;
}