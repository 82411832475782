.VideoEditor-side-all-my-media {
    display: flex;
    overflow-y: scroll;
    height: 100%;
}

.VideoEditor-side-all-my-media::-webkit-scrollbar {
    width: 8px;
}

.VideoEditor-side-all-my-media::-webkit-scrollbar-track {
    background-color: #e4e4e4;
}

.VideoEditor-side-all-my-media::-webkit-scrollbar-thumb {
    background-color: #a2a2a2;
}

.VideoEditor-side-container-my-media {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgb(226, 233, 238);
    width: 100%;
}

.VideoEditor-side-container-my-media>#drag-area {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* padding: 20px; */
    height: 100%;
    width: 100%;
}

.VideoEditor-side-container-my-media>#drag-area.drag-active {
    background-color: rgb(165, 241, 152) !important;
    border: 1px dashed black;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery {
    display: flex;
    align-content: flex-start;
    flex-wrap: wrap;
    padding: 5px;
    height: 100%;
    width: 100%;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery .img-con {
    display: flex;
    position: relative;
    margin-right: 5px;
    margin-bottom: 5px;
    height: max-content;
    cursor: move;
    flex-direction: column;
    align-items: center;
    border-radius: 10px;
    overflow: hidden;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery .img-con:hover {
    border: 1px solid #000;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery .img-con .im {
    height: 90px;
    width: 180px;
    object-fit: fill;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery>.img-con>#name {
    bottom: 0;
    left: 0;
    right: 0;
    color: #fff;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
}

.VideoEditor-side-container-my-media>#drag-area>.image-gallery>.img-con>#duration {
    top: 0;
    right: 0;
    color: #fff;
    padding: 0 4px;
    font-size: 0.8rem;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.9);
    position: absolute;
}